import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector, useDispatch } from "react-redux";
import {
  GetDocumentAnalysis,
  ResetAnalysis,
} from "../../actions/analysisActions";
import { Tooltip } from "primereact/tooltip";
import api from "../../interceptors/api";

/**
  file - uploaded file
  language - language of file
  className - additional class name for root element
  enableDomainDetection - enable domain detection
 */
const Analysis = ({ file, language, className = "", enableDomainDetection = false }) => {
  const analysis = useSelector((state) => state.analysis);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!file || !language) {
      dispatch(ResetAnalysis());
      return;
    }

    dispatch(GetDocumentAnalysis(file, language));
  }, [file, language, dispatch]);

  let i = 0;

  const [detectedDomain, setDetectedDomain] = useState(null);

  /**
   * Domain detection
   */
  React.useEffect(() => {
    if (!enableDomainDetection) {
      return;
    }

    if (!file || !language) {
      setDetectedDomain(null);
      return;
    }

    const getFormData = () => {
      return {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    };

    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("language", language);

    api
      .post(
        `/catalogue/api/domain-detection-document`,
        bodyFormData,
        getFormData()
      )
      .then((res) => {
        console.log(res.data);

        setDetectedDomain(res.data);
      });

    // setDetectedDomain("/");
  }, [file, language, enableDomainDetection]);

  /**
   * show field value or '/' if field value is null
   */
  const emptyBody = (rowData, row, placeholder = "/") => {
    return rowData[row.field] != null ? rowData[row.field] : placeholder;
  };

  return (
    <div className={`w-full ${className}`}>
      <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
        <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
        <div className="px-2 z-1 surface-0 flex align-items-center">
          <i className="pi pi-search text-900 mr-2"></i>
          <span className="text-900 font-medium">Analysis</span>
        </div>
      </div>
      {/*analysis.errorMsg*/}
      {false ? (
        <div className="col-12">{analysis.errorMsg}</div>
      ) : (
        <div className="col-12">
          <DataTable
            value={[analysis.data]}
            className="translate-ui-full-width"
            loading={analysis.loading}
          >
            <Column
              field="sentence_count"
              header="Sentence count"
              body={emptyBody}
            ></Column>
            <Column
              field="word_count"
              header="Word count"
              body={emptyBody}
            ></Column>
            <Column
              field="char_count"
              header="Character count"
              body={emptyBody}
            ></Column>
            <Column
              field="avg_sentence_length"
              header="Average sentence length"
              body={emptyBody}
            ></Column>
            <Column
              field="avg_word_length"
              header="Average word length"
              body={emptyBody}
            ></Column>
            <Column
              field="readability_score"
              header={
                <>
                  Readability{" "}
                  <i className="pi pi-info-circle p-dt-tooltip-readability">
                    <Tooltip
                      target=".p-dt-tooltip-readability"
                      position="top"
                      content="Readability is a measure of how easy it is to read and understand a text. The score ranges from 1 to 18. The lower the score, the easier it is to read."
                    />
                  </i>
                </>
              }
              body={emptyBody}
            ></Column>
            <Column header="Domain*" body={detectedDomain || "/"} />
          </DataTable>
        </div>
      )}
    </div>
  );
};

export default React.memo(Analysis);
