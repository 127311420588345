import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import { MicrosoftAuthenticate } from "../../actions/authActions";

export const getLogger = (isDebugMode) => (name, content, isError) => {
  if (isDebugMode) {
    const style = `background-color: ${
      isError ? "#990000" : "#009900"
    }; color: #ffffff; font-weight: 700; padding: 2px`;
    console.groupCollapsed("MSLogin debug");
    console.log(`%c${name}`, style);
    content && console.log(content.message || content);
    console.groupEnd();
  }
};

const MicrosoftLogin = ({ debug = false, msalInstance, loading }) => {
  const dispatch = useDispatch();
  const log = getLogger(debug);
  const scope = ["user.read"];

  const login = async () => {
    try {
      log("Fetch Azure AD 'token' with redirect STARTED", null, false);
      await msalInstance.initialize();
      await msalInstance.acquireTokenRedirect({ scopes: scope });
    } catch (error) {
      log("Fetch Azure AD 'token' with redirect FAILED", error, true);
    }
  };

  useEffect(() => {
    async function initMsalInstance() {
      await msalInstance.initialize();
      return msalInstance;
    }
    // This will be run on component mount
    initMsalInstance().then((msalInstance) => {
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (tokenResponse !== null && tokenResponse.account != null) {
            log(
              "Fetch Azure AD 'token' with redirect COMPLETED",
              tokenResponse,
              false
            );
            dispatch(MicrosoftAuthenticate(tokenResponse.accessToken));
          }
        })
        .catch((e) => {
          log("Fetch Azure AD 'token' with redirect FAILED", e, true);
        });
    });
  }, []);

  return (
    <MicrosoftLoginButton buttonTheme="light" onClick={login} loading={loading}>
      Login with Microsoft
    </MicrosoftLoginButton>
  );
};

export default MicrosoftLogin;
