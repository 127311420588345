import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import { CRS } from "leaflet/dist/leaflet-src.esm";
import { ProgressSpinner } from "primereact/progressspinner";
import { SelectButton } from "primereact/selectbutton";
import { Splitter, SplitterPanel } from "primereact/splitter";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Polygon,
  Tooltip,
  useMap,
} from "react-leaflet";
import { CopyInputTextArea } from "../utils/CopyInputTextArea";
import { hw } from "./leafletUtils";

/**
 * Component that renders a select button for OCR views.
 */
export const SelectButtonOCRView = ({
  viewVisibility,
  setViewVisibility,
  allow_text = true,
  allow_translation = true,
}) => {
  const valueFromViewVisibility = () => {
    // Filter and convert the keys of viewVisibility object to integers
    const defaultValues = Object.keys(viewVisibility)
      .filter((key) => viewVisibility[key])
      .map((key) => parseInt(key, 10));

    return defaultValues.length > 0 ? defaultValues : [1]; // Provide default selection
  };

  // State for storing the selected values
  const [value, setValue] = useState(valueFromViewVisibility());

  useEffect(() => {
    setValue(valueFromViewVisibility());
  }, [viewVisibility]);

  // Options for the SelectButton component
  const viewOptions = [
    {
      // label: "View document",
      value: 1,
      icon: "pi pi-image",
    }, // Added labels for accessibility
  ];
  // Only show text and translation options if they are allowed
  if (allow_text) {
    viewOptions.splice(1, 0, {
      // label: "View text",
      value: 2,
      icon: "pi pi-align-left",
    });
  }

  if (allow_translation) {
    viewOptions.splice(2, 0, {
      // label: "View translation",
      value: 3,
      icon: "pi pi-globe",
    });
  }

  // Event handler for handling selected values
  const handleSelectedValues = (selectedValues) => {
    // If no values are selected, do nothing
    if (selectedValues.length === 0) {
      return;
    }

    // Create an updated visibility object based on the selected values
    const updatedVisibility = {};

    viewOptions.forEach((option) => {
      updatedVisibility[option.value] = selectedValues.includes(option.value);
    });

    // Update the visibility state and the selected values
    setViewVisibility(updatedVisibility);
    setValue(
      Object.keys(updatedVisibility)
        .filter((key) => updatedVisibility[key])
        .map((key) => parseInt(key, 10)),
    );
  };

  // Render function for custom item template
  const itemTemplate = (option) => {
    return <i className={option.icon + " p-button-icon"}></i>;
  };

  return (
    <SelectButton
      value={value}
      options={viewOptions}
      onChange={(e) => handleSelectedValues(e.value)}
      optionLabel="label"
      itemTemplate={itemTemplate}
      multiple
    />
  );
};

/**
 * Image component
 * Use memo to prevent re-rendering when the image is the same, i.e. resizing the map

 * @param mapRef - reference to the map
 * @param image - image object
 * @returns {JSX.Element} - image overlay
 * @constructor
 */
const ImageComponent = memo(({ image }) => {
  const map = useMap();

  const imageBounds = useMemo(() => {
    const origin = hw([0, 0], null);
    const corner = hw([image.height, image.width], null);
    return [origin, corner];
  }, [image.height, image.width]);

  // Resize map to fit image bounds
  useEffect(() => {
    map.fitBounds(imageBounds, { animate: true });
  }, [map, imageBounds]);

  return (
    <ImageOverlay
      url={image.src}
      bounds={imageBounds}
      opacity={1}
      zIndex={10}
    />
  );
});

/**
 * OCRed page view
 * @param props
 *    props.file: file URL object of image
 *    props.text: text string of transcribed text
 *    props.geojson: geojson with overlay
 * @returns {JSX.Element}
 * @constructor
 */
export const OCRSplitview = ({
  file_url,
  text,
  setText,
  geojson,
  translation,
  setTranslation,
  allow_text = false,
  allow_translation = false,
  viewVisibility,
}) => {
  const [leafletMarkers, setLeafletMarkers] = useState([]);

  const [map, setMap] = useState(null);

  const [image, setImage] = useState(new Image());
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  // Load image
  useEffect(() => {
    const img = new Image();

    if (!file_url) {
      // No image to load, set image to empty
      setImage(img);
      setImageError(false);
      setImageLoading(false);
      return;
    }

    setImageLoading(true); // Show loading indicator

    img.onload = function () {
      setImageLoading(false);
      setImageError(false);
      setImage(img);
    };

    img.onerror = function () {
      setImageLoading(false);
      setImageError(true);
    };

    img.src = file_url;
  }, [file_url]);

  // Generate markers for geojson, only when allow_text or allow_translation is true
  useEffect(() => {
    if (geojson && (allow_text || allow_translation)) {
      getLeafletMarkers(geojson);
    } else {
      setLeafletMarkers([]);
    }
  }, [geojson, allow_text, allow_translation]);

  const getLeafletMarkers = (geojson) => {
    geojson = geojson || { features: [] }; // Set an empty geojson object as default

    const leafletMarkersArr = geojson.features.map((c) => {
      const bounds = c.geometry.coordinates.map(hw);
      const popupMessage = c.properties.name;
      return { popupMessage, bounds };
    });

    setLeafletMarkers(leafletMarkersArr);
  };

  const LeafletMarkers = memo(({ leafletMarkers }) => {
    const map = useMap();

    return leafletMarkers.map((marker, id) => {
      return (
        <Polygon
          key={id}
          positions={marker.bounds}
          eventHandlers={{
            click: () => {
              // Estimate center of polygon
              const center = marker.bounds.reduce(
                function (cnt, boundi) {
                  return [
                    cnt[0] + boundi.lat / marker.bounds.length,
                    cnt[1] + boundi.lng / marker.bounds.length,
                  ];
                },
                [0, 0],
              );

              map.setView([center[0], center[1]], 3);
            },
          }}
        >
          <Tooltip
            className="occ-leaflet-tooltip"
            direction="bottom"
            offset={[0, 20]}
            opacity={1}
          >
            {marker.popupMessage}
          </Tooltip>
        </Polygon>
      );
    });
  });

  // Reset the map size when necessary
  const ResetMapHook = () => {
    const map = useMap();

    useEffect(() => {
      map.invalidateSize({ animate: true });
    }, [map]);
  };

  return (
    <div className="occ-full-width flex flex-column flex-grow-1">
      <Splitter
        className="flex-grow-1"
        //   style={{
        //     // height: splitterHeight
        //     height: "100%"
        // }}
        layout={"horizontal"}
        stateKey={"ocr-splitter"}
        stateStorage={"local"}
        onResizeEnd={(e) => {
          // Reset the map size when the splitter is resized
          if (map) {
            map.invalidateSize({ animate: true });
          }
        }}
      >
        <SplitterPanel
          key="panelDocument"
          minSize={10}
          className={!viewVisibility[1] && "hidden"}
        >
          <MapContainer
            center={[0, 0]}
            scrollWheelZoom={true}
            crs={CRS.Simple}
            ref={setMap}
          >
            <ResetMapHook />
            {imageLoading && (
              <div
                className="flex flex-column align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <ProgressSpinner />
              </div>
            )}
            {imageError && (
              <div
                className="flex flex-column align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <HideImageOutlinedIcon style={{ fontSize: 100 }} />
                <p>Failed to load image</p>
              </div>
            )}
            {!!image.width && <ImageComponent image={image} />}
            <LeafletMarkers leafletMarkers={leafletMarkers} />
          </MapContainer>
        </SplitterPanel>

        <SplitterPanel
          key="panelText"
          minSize={10}
          className={!viewVisibility[2] && "hidden"}
        >
          <CopyInputTextArea
            text={text}
            setText={setText}
            placeholder={"Transcription..."}
          />
        </SplitterPanel>

        <SplitterPanel
          key="panelTranslation"
          minSize={10}
          className={!viewVisibility[3] && "hidden"}
        >
          <CopyInputTextArea
            text={translation}
            setText={setTranslation}
            placeholder="Translation..."
          />
        </SplitterPanel>
      </Splitter>
    </div>
  );
};

export default OCRSplitview;
