import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../../interceptors/api";

const customSelectColors = {
  primary: "var(--primary-color)",
  primary25: "var(--accent-color)",
  primary50: "var(--primary-color-lightest)",
  primary75: "var(--primary-color-lightest)",
};

/**
 * Glossary component (switch + manager)
 * @param inputText: Original text
 * @param translatedText: The translated text to be corrected
 * @param sourceLanguage: Source language
 * @param targetLanguage: Target language
 * @param translationGlossary: the corrected translation
 * @param setTranslationGlossary: % setter
 * @param enableGlossary: boolean to enable/disable glossary
 * @param setEnableGlossary: % setter
 * @returns {JSX.Element}
 * @constructor
 */
const Glossary = ({
  inputText,
  translatedText,
  sourceLanguage,
  targetLanguage,
  translationGlossary,
  setTranslationGlossary,
  enableGlossary,
  setEnableGlossary,
}) => {
  // Backend glossary id
  const [glossaryId, setGlossaryId] = useState(null);

  // Glossary manager dialog
  const [enableGlossaryManager, setEnableGlossaryManager] = useState(false);

  // Glossary terms to be displayed in the table
  const [glossary, setGlossary] = useState([]);

  // Selected options. Contains label and value { label: "English", value: "en" }
  const [selectedTermSourceLanguage, setSelectedTermSourceLanguage] =
    useState(null);
  const [selectedTermTargetLanguage, setSelectedTermTargetLanguage] =
    useState(null);
  const [sourceTerm, setSourceTerm] = useState("");
  const [targetTerm, setTargetTerm] = useState("");

  /**
   * Correct the translation using a glossary
   * */
  useEffect(() => {
    if (!translatedText) {
      setTranslationGlossary("");
      return;
    }

    if (glossaryId === null) {
      return;
    }

    let formData = new FormData();
    formData.append("text_source", inputText);
    formData.append("text_target", translatedText);
    formData.append("source_language", sourceLanguage.value);
    formData.append("target_language", targetLanguage.value);
    formData.append("glossary", glossaryId);

    api
      .post(`/terminology/api/translation-correction-terminology`, formData)
      .then((response) => {
        setTranslationGlossary(response.data);
      });
  }, [translatedText, glossaryId]);

  // Dropdown menu options
  const availableSourceLanguages = useSelector(
    (state) => state.availableSourceLanguages,
  );

  const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]);

  // Fetch available languages
  const getAvailableTargetLanguages = async () => {
    const res = await api.get(`/catalogue/api/target-languages`);

    setAvailableTargetLanguages(res.data);
  };

  // Fetch glossary terms
  const getTerms = async (glossary_id) => {
    // Get terms
    let termFormData = new FormData();
    const data = { glossary: glossary_id };

    api.get("/terminology/api/term").then((res) => {
      setGlossary(annotateGlossaryGroup(res.data));
    });
  };

  /**
   * Fetch available target languages.
   */
  useEffect(() => {
    if (!availableTargetLanguages.length) {
      getAvailableTargetLanguages().then((res) => {});
    }
  }, [availableTargetLanguages]);

  /**
   * Set default source language
   */
  useEffect(() => {
    if (!selectedTermSourceLanguage) {
      setSelectedTermSourceLanguage(availableSourceLanguages.data[0]);
    }
  }, [availableSourceLanguages]);

  /**
   * Set default target language
   */
  useEffect(() => {
    if (!selectedTermTargetLanguage) {
      setSelectedTermTargetLanguage(availableTargetLanguages[1]);
    }
  }, [availableTargetLanguages]);

  /**
   * Get default glossary
   */
  useEffect(() => {
    api.get("/terminology/api/glossary/default").then((res) => {
      const glossary_id = res.data.id;
      setGlossaryId(glossary_id);

      // Get terms
      getTerms(glossary_id);
    });
  }, []);

  const checkTermValidity = () => {
    // Check if values are selected, else give warning and return
    if (!selectedTermSourceLanguage || !selectedTermTargetLanguage) {
      return false;
    }
    // Strip whitespace from source and target terms
    const validSourceTerm = sourceTerm.trim();
    const validTargetTerm = targetTerm.trim();

    // Check if source and target terms are empty
    return validSourceTerm && validTargetTerm;
  };

  const createTerms = async () => {
    let formData = new FormData();

    // Strip whitespace from source and target terms
    const validSourceTerm = sourceTerm.trim();
    const validTargetTerm = targetTerm.trim();

    formData.append("source_language", selectedTermSourceLanguage.value);
    formData.append("target_language", selectedTermTargetLanguage.value);
    formData.append("source_text", validSourceTerm);
    formData.append("target_text", validTargetTerm);
    formData.append("glossary", glossaryId);

    await api.post(`terminology/api/term`, formData).then((res) => {
      // Update glossary
      getTerms();
    });
  };

  const annotateGlossaryGroup = (glossary) => {
    const glossary_temp = glossary;

    glossary_temp.forEach((entry) => {
      entry.language_pair =
        entry.source_language + " > " + entry.target_language;
    });
    return glossary_temp;
  };

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="font-bold">
          `{data.source_language} > {data.target_language}`
        </span>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Button
        className="flex align-items-center p-button-outlined"
        onClick={(e) => {
          // Check if the click target is not the InputSwitch
          if (!e.target.classList.contains("p-inputswitch-slider")) {
            setEnableGlossaryManager(!enableGlossaryManager);
          }
        }}
      >
        <InputSwitch
          checked={enableGlossary}
          onChange={(e) => {
            // Prevent the click event from propagating to the Button
            e.stopPropagation();
            setEnableGlossary(e.value);
          }}
          style={{ zIndex: 1 }}
          onClick={(e) => e.stopPropagation()} // Prevent the Button click event
        />

        <label className={"ml-2"}>Glossary</label>
        <i className="pi pi-bars ml-2"></i>
      </Button>

      <Dialog
        header="Glossary"
        visible={enableGlossaryManager}
        onHide={() => setEnableGlossaryManager(false)}
        modal={false}
      >
        <div // Width of the table 50 % of whole screen
          style={{ width: "500px" }}
        >
          <div className={"mb-2"}>
            <div className="font-bold pb-2">Default glossary</div>

            <div className="flex justify-content-between">
              <div className="flex flex-column w-full">
                <div className="flex flex-row pb-2 w-full">
                  <Select
                    className="z-4 w-8rem"
                    options={availableSourceLanguages.data}
                    value={selectedTermSourceLanguage}
                    placeholder="Select source language..."
                    onChange={setSelectedTermSourceLanguage}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...customSelectColors,
                      },
                    })}
                    isLoading={availableSourceLanguages.loading}
                  />
                  <InputText
                    className={"pt-0 pb-0 flex-grow-1"}
                    id="source_text"
                    name="source_text"
                    value={sourceTerm}
                    onChange={(e) => setSourceTerm(e.target.value)}
                    autoComplete={"off"}
                    placeholder="Source text"
                  />
                </div>

                <div className={"flex flex-row"}>
                  <Select
                    className="z-3 w-8rem"
                    options={availableTargetLanguages}
                    value={selectedTermTargetLanguage}
                    placeholder="Select target language..."
                    onChange={setSelectedTermTargetLanguage}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...customSelectColors,
                      },
                    })}
                  />
                  <InputText
                    className={"pt-0 pb-0 flex-grow-1"}
                    id="target_text"
                    name="target_text"
                    value={targetTerm}
                    onChange={(e) => setTargetTerm(e.target.value)}
                    autoComplete={"off"}
                    placeholder="Target text"
                  />
                </div>
              </div>

              <div className="flex align-items-center ml-3">
                <Button
                  icon={"pi pi-plus"}
                  onClick={createTerms}
                  disabled={!checkTermValidity()}
                />
              </div>
            </div>
          </div>

          <DataTable
            value={glossary}
            rowGroupMode={"subheader"}
            groupRowsBy={"language_pair"}
            sortField={"language_pair"}
            sortOrder={1}
            rowGroupHeaderTemplate={headerTemplate}
            scrollable
            scrollHeight="50vh"
            size={"small"}
          >
            <Column
              field="source_text"
              headerStyle={{ display: "none" }}
              style={{ width: "40%" }}
            />
            <Column
              field="target_text"
              headerStyle={{ display: "none" }}
              style={{ width: "40%" }}
            />
            <Column
              style={{ flexGrow: 0, flexBasis: "1rem" }}
              className={"p-text-center"}
              headerStyle={{ display: "none" }}
              body={(rowData) => {
                // Delete term button
                return (
                  <Button
                    icon={"pi pi-times"}
                    className="mtg-mini-btn p-button-rounded p-button-danger p-button-text"
                    onClick={() => {
                      api
                        .delete(`/terminology/api/term/${rowData.id}`)
                        .then((res) => {
                          // Update glossary
                          getTerms();
                        });
                    }}
                  />
                );
              }}
            />
          </DataTable>
        </div>
      </Dialog>
    </div>
  );
};

export default Glossary;
