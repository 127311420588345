import { AuthActionTypes } from "../constants/auth-action-types";

const DefaultState = {
  loading: false,
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: localStorage.getItem("isAuthenticated"),
  hasAcceptedTerms: localStorage.getItem("hasAcceptedTerms"),
  // hasCompletedTutorial: true,
  user: "",
  created: "",
  has_subscription: false,
  company: "",
  role: [],
  permissions: JSON.parse(localStorage.getItem("permissions")) || [],
  errorMsg: "",
  showSuccessMessage: false,
  showNotInvitedMessage: false,
  showPasswordResetSuccessMessage: false,
  showForgotPasswordMessage: false,
};

const AuthReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case AuthActionTypes.GOOGLE_AUTH_LOADING:
    case AuthActionTypes.MICROSOFT_AUTH_LOADING:
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case AuthActionTypes.GOOGLE_AUTH_FAIL:
    case AuthActionTypes.MICROSOFT_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
        access: null,
        refresh: null,
        isAuthenticated: false,
        user: null,
        hasAcceptedTerms: false,
        permissions: [],
      };
    case AuthActionTypes.GOOGLE_AUTH_SUCCESS:
    case AuthActionTypes.MICROSOFT_AUTH_SUCCESS:
      const accessToken = action.payload.access_token;
      const refreshToken = action.payload.refresh_token;

      localStorage.setItem("access", accessToken);
      localStorage.setItem("refresh", refreshToken);
      localStorage.setItem("isAuthenticated", "true");
      // assume user has accepted terms and conditions,
      // if they haven't, they will be redirected to the terms page
      localStorage.setItem("hasAcceptedTerms", "true");
      // assume user has access rights to all features,
      // if they don't, they will be redirected to the forbidden page
      localStorage.setItem("permissions", JSON.stringify(["*"]));
      return {
        ...state,
        loading: false,
        access: accessToken,
        refresh: refreshToken,
        isAuthenticated: true,
        errorMsg: "",
        hasAcceptedTerms: true,
        permissions: ["*"],
      };

    case AuthActionTypes.AUTH_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case AuthActionTypes.AUTH_LOGIN_FAILED:
      return {
        ...state,
        errorMsg: "Invalid credentials",
        loading: false,
      };

    case AuthActionTypes.AUTH_LOGIN_SUCCESS:
      const accessTokenDjango = action.payload.access_token;
      const refreshTokenDjango = action.payload.refresh_token;

      localStorage.setItem("access", accessTokenDjango);
      localStorage.setItem("refresh", refreshTokenDjango);
      localStorage.setItem("isAuthenticated", "true");
      // assume user has accepted terms and conditions,
      // if they haven't, they will be redirected to the terms page
      localStorage.setItem("hasAcceptedTerms", "true");
      // assume user has access rights to all features,
      // if they don't, they will be redirected to the forbidden page
      localStorage.setItem("permissions", JSON.stringify(["*"]));

      return {
        ...state,
        loading: false,
        access: accessTokenDjango,
        refresh: refreshTokenDjango,
        isAuthenticated: true,
        errorMsg: "",
        hasAcceptedTerms: true,
        permissions: ["*"],
      };

    case AuthActionTypes.AUTH_LOGIN_IP_SUCCESS:
      const ipAccessTokenDjango = action.payload.access_token;
      localStorage.setItem("access", ipAccessTokenDjango);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("hasAcceptedTerms", "true");
      localStorage.setItem("permissions", JSON.stringify(["*"]));

      return {
        ...state,
        loading: false,
        access: ipAccessTokenDjango,
        isAuthenticated: true,
        errorMsg: "",
        hasAcceptedTerms: true,
        permissions: ["*"],
      };

    case AuthActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.email,
        created: action.payload.created,
        has_subscription: action.payload.has_subscription,
        company: action.payload.company,
        role: [...action.payload.groups],
        permissions: [...action.payload.user_permissions],
        isAuthenticated: true,
        hasAcceptedTerms: true,
        // hasCompletedTutorial: action.payload.has_completed,
        errorMsg: "",
      };

    case AuthActionTypes.GET_USER_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("hasAcceptedTerms");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        hasAcceptedTerms: false,
        user: null,
        has_subscription: false,
        created: null,
        company: null,
        role: null,
        permissions: [],
        errorMsg: action.payload,
      };
    case AuthActionTypes.HAS_USER_ACCEPTED_TOS_SUCCESS:
      localStorage.setItem("hasAcceptedTerms", "true");
      return {
        ...state,
        hasAcceptedTerms: true,
        errorMsg: "",
      };
    case AuthActionTypes.HAS_USER_ACCEPTED_TOS_FAIL:
      localStorage.setItem("hasAcceptedTerms", "false");
      return {
        ...state,
        hasAcceptedTerms: false,
        errorMsg: action.payload,
      };
    case AuthActionTypes.ACCEPT_TOS_LOADING:
      localStorage.setItem("hasAcceptedTerms", "true");
      return {
        ...state,
        hasAcceptedTerms: true,
        errorMsg: "",
      };
    case AuthActionTypes.ACCEPT_TOS_SUCCESS:
      localStorage.setItem("hasAcceptedTerms", "true");
      return {
        ...state,
        hasAcceptedTerms: true,
        errorMsg: "",
      };
    case AuthActionTypes.ACCEPT_TOS_FAIL:
      localStorage.setItem("hasAcceptedTerms", "false");
      return {
        ...state,
        hasAcceptedTerms: false,
        errorMsg: action.payload,
      };
    case AuthActionTypes.LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("hasAcceptedTerms");
      localStorage.removeItem("permissions");
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        hasAcceptedTerms: false,
        user: null,
        permissions: [],
      };
    case AuthActionTypes.CHANGE_TUTORIAL_STATE_SUCCESS:
      return {
        ...state,
        hasCompletedTutorial: action.payload,
      };
    case AuthActionTypes.MESSAGE_REGISTRATION_NOT_INVITED:
      return {
        ...state,
        showNotInvitedMessage: action.payload,
      };

    case AuthActionTypes.MESSAGE_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        showPasswordResetSuccessMessage: action.payload,
      };
    case AuthActionTypes.MESSAGE_FORGOT_PASSWORD:
      return {
        ...state,
        showForgotPasswordMessage: action.payload,
      };
    case AuthActionTypes.MESSAGE_REGISTRATION_SUCCESS:
      return {
        ...state,
        showSuccessMessage: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
