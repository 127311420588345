import { AuthActionTypes } from "../constants/auth-action-types";
import axios from "axios";
import { baseUrl, clientId, clientSecret } from "../constants/axiosConf";
import { GetThemeConfig } from "./uiActions";
import api from "../interceptors/api";

export const LoadUser = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    dispatch({
      type: AuthActionTypes.GET_USER_LOADING,
    });

    try {
      const res = await api.get("/auth/me");

      dispatch({
        type: AuthActionTypes.GET_USER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AuthActionTypes.GET_USER_FAIL,
      });
    }
  } else {
    dispatch({
      type: AuthActionTypes.GET_USER_FAIL,
    });
  }
};

export const HasUserAcceptedToS = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    dispatch({
      type: AuthActionTypes.HAS_USER_ACCEPTED_TOS_LOADING,
    });

    try {
      const res = await api.get("/terms/api/accepted");

      dispatch({
        type: AuthActionTypes.HAS_USER_ACCEPTED_TOS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AuthActionTypes.HAS_USER_ACCEPTED_TOS_FAIL,
      });
    }
  } else {
    dispatch({
      type: AuthActionTypes.HAS_USER_ACCEPTED_TOS_FAIL,
    });
  }
};

export const AcceptToS = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    dispatch({
      type: AuthActionTypes.ACCEPT_TOS_LOADING,
    });

    try {
      const res = await api.post("/terms/api/accept", {});

      dispatch({
        type: AuthActionTypes.ACCEPT_TOS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AuthActionTypes.ACCEPT_TOS_FAIL,
      });
    }
  } else {
    dispatch({
      type: AuthActionTypes.ACCEPT_TOS_FAIL,
    });
  }
};

export const GoogleAuthenticate = (accessToken) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.GOOGLE_AUTH_LOADING,
    });

    await api
      .post("/auth/convert-token", {
        grant_type: "convert_token",
        client_id: clientId, // REACT_DJANGO_CLIENT_ID
        client_secret: clientSecret, // REACT_DJANGO_CLIENT_SECRET
        backend: "google-oauth2",
        token: accessToken,
      })
      .then((res) => {
        dispatch({
          type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
          payload: res.data,
        });

        dispatch(LoadUser());
        dispatch(HasUserAcceptedToS());
        dispatch(GetThemeConfig());
      });
  } catch (e) {
    dispatch({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
    });
  }
};

export const MicrosoftAuthenticate = (accessToken) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.MICROSOFT_AUTH_LOADING,
    });

    await api
      .post("/auth/convert-token", {
        grant_type: "convert_token",
        client_id: clientId, // REACT_DJANGO_CLIENT_ID
        client_secret: clientSecret, // REACT_DJANGO_CLIENT_SECRET
        backend: "clms",
        token: accessToken,
      })
      .then((res) => {
        dispatch({
          type: AuthActionTypes.MICROSOFT_AUTH_SUCCESS,
          payload: res.data,
        });

        dispatch(LoadUser());
        dispatch(HasUserAcceptedToS());
        dispatch(GetThemeConfig());
      });
  } catch (e) {
    dispatch({
      type: AuthActionTypes.MICROSOFT_AUTH_FAIL,
      errorMsg: e.response.data.detail,
    });
  }
};

export const LoginWithUsername = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.AUTH_LOGIN_LOADING,
    });

    // Don't use api here, may cause an infinite loop if access token is added, call directly
    await axios
      .post(`${baseUrl}/auth/token`, {
        client_id: clientId, // REACT_DJANGO_CLIENT_ID
        client_secret: clientSecret, // REACT_DJANGO_CLIENT_SECRET
        username: username,
        password: password,
        grant_type: "password",
      })
      .then((res) => {
        dispatch({
          type: AuthActionTypes.AUTH_LOGIN_SUCCESS,
          payload: res.data,
        });

        dispatch(LoadUser());
        dispatch(HasUserAcceptedToS());
        dispatch(GetThemeConfig());
      });
  } catch (e) {
    dispatch({
      type: AuthActionTypes.AUTH_LOGIN_FAILED,
    });
  }
};

export const LoginWithIP = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.AUTH_LOGIN_IP_LOADING,
    });

    const res = await axios.get("https://geolocation-db.com/json/");
    const ip_address = res.data.IPv4;

    await api
      .post("/auth/ip", {
        client_id: clientId, // REACT_DJANGO_CLIENT_ID
        client_secret: clientSecret, // REACT_DJANGO_CLIENT_SECRET
        ip_address: ip_address,
      })
      .then((res) => {
        dispatch({
          type: AuthActionTypes.AUTH_LOGIN_IP_SUCCESS,
          payload: res.data,
        });

        dispatch(LoadUser());
        dispatch(HasUserAcceptedToS());
        dispatch(GetThemeConfig());
      });
  } catch (e) {
    dispatch({
      type: AuthActionTypes.AUTH_LOGIN_IP_FAILED,
    });
  }
};

export const RegisterUser = (email, password, name) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.AUTH_REGISTER_LOADING,
    });

    await api
      .post("/auth/register/", {
        email: email,
        username: email,
        password: password,
        name: name,
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: AuthActionTypes.AUTH_REGISTER_SUCCESS,
            payload: res.data,
          });
          dispatch(ModifyRegistrationSuccessMessage(true));
          dispatch(LoginWithUsername(email, password));
        } else if (res.status === 203) {
          dispatch({
            type: AuthActionTypes.AUTH_REGISTER_NOT_INVITED,
            payload: res.data,
          });
          dispatch(ModifyUserNotInvitedMessage(true));
        }
      });
  } catch (e) {
    dispatch({
      type: AuthActionTypes.AUTH_REGISTER_FAILED,
    });
  }
};

export const ModifyRegistrationSuccessMessage = (value) => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.MESSAGE_REGISTRATION_SUCCESS,
    payload: value,
  });
};

export const ModifyUserNotInvitedMessage = (value) => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.MESSAGE_REGISTRATION_NOT_INVITED,
    payload: value,
  });
};

export const ModifyResetSuccessMessage = (value) => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.MESSAGE_PASSWORD_RESET_SUCCESS,
    payload: value,
  });
};

export const ModifyForgotPasswordMessage = (value) => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.MESSAGE_FORGOT_PASSWORD,
    payload: value,
  });
};

export const Logout = () => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.LOGOUT,
  });
};

export const ChangeTutorialState = (userId, value) => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.CHANGE_TUTORIAL_STATE_LOADING,
  });

  try {
    await api.post("/tutorial/api/usertutorials", {
      user: userId,
      value: value,
    });

    dispatch({
      type: AuthActionTypes.CHANGE_TUTORIAL_STATE_SUCCESS,
      payload: value,
    });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.CHANGE_TUTORIAL_STATE_FAILED,
    });
  }
};

export const CloseTutorial = () => async (dispatch) => {
  dispatch({
    type: AuthActionTypes.CHANGE_TUTORIAL_STATE_SUCCESS,
    payload: true,
  });
};

export const ResetPassword = (reset_code, new_password) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.AUTH_RESET_PASSWORD_LOADING,
    });

    await api
      .post("/auth/reset-password", {
        reset_code,
        new_password,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: AuthActionTypes.AUTH_RESET_PASSWORD_SUCCESS,
          });
          dispatch(ModifyResetSuccessMessage(true));
        }
      });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.AUTH_RESET_PASSWORD_FAILED,
    });
  }
};

export const ForgotPasswordRequest = (email) => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.AUTH_FORGOT_PASSWORD_LOADING,
    });

    await api
      .post("/auth/forgot-password", {
        email,
      })
      .then((res) => {
        dispatch({
          type: AuthActionTypes.AUTH_FORGOT_PASSWORD_SUCCESS,
        });
        dispatch(ModifyForgotPasswordMessage(true));
      });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.AUTH_FORGOT_PASSWORD_FAILED,
    });
  }
};

export const GetInvitedUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthActionTypes.GET_INVITED_USERS_LOADING,
    });

    await api.get("/auth/invited").then((res) => {
      dispatch({
        type: AuthActionTypes.GET_INVITED_USERS_SUCCESS,
        payload: res.data,
      });
    });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.GET_INVITED_USERS_FAILED,
    });
  }
};
