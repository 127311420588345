import fileDownload from "js-file-download";
import JSZip from "jszip";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ResetTranslatedFileStatus } from "../../actions/translationActions";
import api from "../../interceptors/api";
import Analysis from "../translate/Analysis";
import TranslateEngineSelection from "../translate/TranslateEngineSelection";

import { OCRSplitview, SelectButtonOCRView } from "./OCRSplitview";
import { PageSelection } from "./PageSelection";
import ProgressBar from "./ProgressBar";

const TOAST_LIFE = 10000;

const updateArrayAtIndex = (array, index, data) => {
  const newArray = [...array];
  if (index >= newArray.length) {
    newArray.length = index + 1;
  }
  newArray[index] = data;
  return newArray;
};

const getFormConfig = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
};

const RenderActionMenu = ({
  activeStep,
  setActiveStep,
  indexPage,
  isOCRingFile,
  isTranslating,
  transcription,
  setDisplayOCROptions,
  setDisplayTranslationOptions,
  setDisplayDownloadOptions,
  toast,
}) => {
  return (
    <div className="flex flex-row justify-content-between mt-3">
      <Button
        label="Prev"
        icon="pi pi-arrow-left"
        // className="w-auto mt-4 border-400"
        onClick={(e) => {
          let nextStep = activeStep - 1;
          // Bound between 0 and 3
          nextStep = Math.min(nextStep, 3);
          nextStep = Math.max(nextStep, 0);
          setActiveStep(nextStep);
        }}
        disabled={activeStep <= 0}
      />

      <div className="flex">
        {activeStep === 2 && (
          <Button
            label={
              isOCRingFile ? (
                <ProgressSpinner className={"translate-loading"} />
              ) : (
                "Extract text"
              )
            }
            className="translate-button cl-primary-background"
            onClick={(_) => setDisplayOCROptions(true)}
            disabled={isOCRingFile || indexPage === null}
          />
        )}

        {activeStep === 3 && (
          <Button
            label={
              isTranslating ? (
                <ProgressSpinner className={"translate-loading"} />
              ) : (
                "Translate text"
              )
            }
            className="translate-button cl-primary-background"
            onClick={(_) => setDisplayTranslationOptions(true)}
            disabled={
              isTranslating || indexPage === null || !transcription[indexPage]
            }
          />
        )}

        {activeStep >= 2 && (
          <Button
            icon={"pi pi-download"}
            className="ml-3"
            onClick={(_) => setDisplayDownloadOptions(true)}
          />
        )}
      </div>

      <Button
        label="Next"
        icon="pi pi-arrow-right"
        // className="w-auto mt-4 border-400"
        onClick={(e) => {
          let nextStep = activeStep + 1;
          // Bound between 0 and 3
          nextStep = Math.min(nextStep, 3);
          nextStep = Math.max(nextStep, 0);
          setActiveStep(nextStep);
        }}
        disabled={activeStep >= 3}
      />
    </div>
  );
};

const RenderTranslationFooter = ({
  transcription,
  selectedSourceLanguage,
  selectedTargetLanguage,
  selectedProvider,
  selectedDomain,
  setTranslation,
  setIsTranslating,
  setDisplayTranslationOptions,
  toast,
}) => {
  /**
   * Translate a text result
   */
  const TranslateOCRSingle = async (
    text,
    pageIndex,
    source,
    target,
    provider,
    domain,
  ) => {
    let bodyFormData = new FormData();
    bodyFormData.append("text", text);

    bodyFormData.append("source", source);
    bodyFormData.append("target", target);
    bodyFormData.append("provider", provider);
    bodyFormData.append("domain", domain);

    await api
      .post(
        `/catalogue/api/translate-text-blocking`,
        bodyFormData,
        getFormConfig(),
      )
      .then((res) => {
        setTranslation((prevTranslation) =>
          updateArrayAtIndex(prevTranslation, pageIndex, res.data),
        );
      })
      .catch(() => {
        console.error(`Translation failed for page ${pageIndex}`);

        toast.current.show({
          life: TOAST_LIFE,
          severity: "error",
          summary: "Translation",
          detail:
            "Something went wrong with the translation for one or more pages. Please try again later or contact an administrator.",
        });

        setTranslation((prevTranslation) =>
          updateArrayAtIndex(prevTranslation, pageIndex, null),
        );
      });
  };

  /**
   * Translate all the OCR text results
   * @returns {Promise<void>}
   */
  const translateOCRAll = async (source, target, provider, domain) => {
    toast.current.show({
      severity: "info",
      summary: "Translation",
      detail: "Translation started...",
      life: TOAST_LIFE,
    });

    setIsTranslating(true);
    try {
      for (const transcription_i of transcription) {
        const index = transcription.indexOf(transcription_i);
        await TranslateOCRSingle(
          transcription_i,
          index,
          source,
          target,
          provider,
          domain,
        );
      }
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <Button
      label="Translate transcription"
      onClick={() => {
        translateOCRAll(
          selectedSourceLanguage.value,
          selectedTargetLanguage.value,
          selectedProvider.value,
          selectedDomain.value,
        ).then(() => {});
        setDisplayTranslationOptions(false);
      }}
      disabled={
        !selectedSourceLanguage?.value ||
        !selectedTargetLanguage?.value ||
        !selectedProvider?.value ||
        !selectedDomain?.value
      }
    />
  );
};

const RenderDownloadMenu = ({
  transcription,
  translation,
  setDisplayDownloadOptions,
  toast,
}) => {
  const [checkedTranscription, setCheckedTranscription] = useState(true);
  const [checkedTranslation, setCheckedTranslation] = useState(false);

  const [enableTranscription, setEnableTranscription] = useState(true);
  const [enableTranslation, setEnableTranslation] = useState(false);

  useEffect(() => {
    // If there is no transcription, disable the transcription checkbox
    if (transcription.length === 0 || transcription.every((t) => !t)) {
      setEnableTranscription(false);
    } else {
      setEnableTranscription(true);
    }
  }, [transcription]);

  useEffect(() => {
    // If there is no translation, disable the translation checkbox
    if (translation.length === 0 || translation.every((t) => !t)) {
      setEnableTranslation(false);
    } else {
      setEnableTranslation(true);
    }
  }, [translation]);

  useEffect(() => {
    setCheckedTranscription(enableTranscription);
  }, [enableTranscription]);

  useEffect(() => {
    setCheckedTranslation(enableTranslation);
  }, [enableTranslation]);

  /**
   * Download the results of the OCR and translation
   * @param include_transcription: whether to download the transcription
   * @param include_translation: whether to download the translation
   * @returns {Promise<void>}
   */
  const downloadResults = async (
    include_transcription,
    include_translation,
  ) => {
    toast.current.show({
      severity: "info",
      summary: "Download",
      detail: "Download started...",
      life: TOAST_LIFE,
    });

    const parseTranscriptions = (transcription_list) => {
        // Join all the transcriptions into a single text.
        // Split each page with 2 newlines
        // Skip empty pages
        // Makes sure each page ends with a newline
        const list_clean = transcription_list.filter((t) => t).map((t) => t.trim() + "\n");
        return list_clean.join("\n\n");
    }

    try {
      const zip = new JSZip();

      // Add transcription
      if (include_transcription) {
        const transcriptionText= parseTranscriptions(transcription)
        zip.file(`transcription.txt`, transcriptionText)
      }

      // Add translation
      if (include_translation) {
        const translationText= parseTranscriptions(translation)
        zip.file(`translation.txt`, translationText)
      }

      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });

      fileDownload(content, "results_OCR.zip");
    } catch (e) {
      console.error("Error downloading results");

      toast.current.show({
        life: TOAST_LIFE,
        severity: "error",
        summary: "Download",
        detail:
          "Something went wrong with the download. Please try again later or contact an administrator.",
      });
    }
  };

  return (
    <div>
      <p> Select which results to download</p>

      <div className="mb-2">
        <Checkbox
          inputId="cb-transcription"
          checked={checkedTranscription}
          onChange={(e) => setCheckedTranscription(e.checked)}
          disabled={!enableTranscription}
        />
        <label className="ml-2" htmlFor="cb-transcription">
          Transcription
        </label>
      </div>
      <div className="mb-4">
        <Checkbox
          inputId="cb-translation"
          checked={checkedTranslation}
          onChange={(e) => setCheckedTranslation(e.checked)}
          disabled={!enableTranslation}
        />
        <label className="ml-2" htmlFor="cb-translation">
          Translation
        </label>
      </div>
      <div className="flex justify-content-center">
        <Button
          label="Download"
          onClick={() => {
            downloadResults(checkedTranscription, checkedTranslation);
            setDisplayDownloadOptions(false);
          }}
          disabled={!checkedTranscription && !checkedTranslation}
        />
      </div>
    </div>
  );
};

const Ocr = () => {
  const dispatch = useDispatch();

  // Handle file upload and processing
  const uploadRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isImageFileLoading, setIsImageFileLoading] = useState(false);

  // list of pages as images + current selected page
  const [pages, setPages] = useState([]);
  const [indexPage, setIndexPage] = useState(null);
  // Transcription (for each page)
  const [transcription, setTranscription] = useState([]);
  const [geojson, setGeojson] = useState([]);

  // OCR
  const [displayOCROptions, setDisplayOCROptions] = useState(false);
  const [oCROptionsAuto, setOCROptionsAuto] = useState(true);
  const [isOCRingFile, setIsOCRingFile] = useState(false);

  // Translation
  const [displayTranslationOptions, setDisplayTranslationOptions] =
    useState(false);
  const [translationOptionsAuto, setTranslationOptionsAuto] = useState(true);
  // Selected options. Contains label and value { label: "English", value: "en" }
  const [selectedSourceLanguage, setSelectedSourceLanguage] = useState(null);
  const [selectedTargetLanguage, setSelectedTargetLanguage] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  // Whether we are detecting or selecting the source language
  const [languageDetectionMode, setLanguageDetectionMode] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translation, setTranslation] = useState([]);

  // Analysis
  const [analysisFile, setAnalysisFile] = useState(null);
  const [runAnalysis, setRunAnalysis] = useState(false);

  // Download results
  const [displayDownloadOptions, setDisplayDownloadOptions] = useState(false);

  // Viewer
  // State for storing the visibility of the views, default is document view
  const [viewVisibility, setViewVisibility] = useState({
    1: true,
    2: false,
    3: false,
  });

  const toast = useRef(null);

  // 10 MB default
  const MAX_FILESIZE = Number(window._env_.MAX_FILESIZE || 10000000);

  const ALLOWED_FILE_EXTENSIONS = [".png", ".jpg", ".pdf"];

  const [activeStep, setActiveStep] = useState(0);
  const [pagesOpen, setPagesOpen] = useState(true);

  const clearFile = useCallback(() => {
    // Order of setters in reverse order of how they are set

    try {
      uploadRef.current.clear();
    } catch (e) {}

    resetState();
  }, [dispatch]);

  const resetState = useCallback(() => {
    setIsOCRingFile(false);
    setIsImageFileLoading(false);

    dispatch(ResetTranslatedFileStatus());
    setTranslation([]);
    setTranscription([]);
    setAnalysisFile(null);
    setGeojson([]);
    setPages([]);
    setIndexPage(null);
    setUploadedFile(null);
    setOCROptionsAuto(true);
    setTranslationOptionsAuto(true);
  });

  const onTemplateRemove = (file, callback) => {
    callback();
    clearFile();
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex flex-wrap justify-content-between m-0 p-0">
        <div className="flex align-items-center">
          <span className="flex flex-column text-left ml-3">
            {file.name} ({props.formatSize})
          </span>
        </div>

        <div
          className="flex align-items-center justify-content-center ml-auto mr-auto"
          style={{ width: "30%" }}
        >
          {isImageFileLoading && (
            <div>
              <ProgressSpinner className={"translate-loading"} />
              <span>Extracting pages...</span>
            </div>
          )}
        </div>
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button-text"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const fileUploader = async (event) => {
    // Reset by default
    resetState();

    const files = event.files;

    if (files) {
      const uploadedFile = files[0];
      const fileExtension = uploadedFile.name.slice(
        uploadedFile.name.lastIndexOf("."),
      );

      if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
        toast.current.show({
          life: TOAST_LIFE,
          severity: "error",
          summary: "Invalid file format",
          detail: `File format "${fileExtension}" is not supported. Please upload a file with one of the following extensions: ${ALLOWED_FILE_EXTENSIONS.join(
            ", ",
          )}.`,
        });
        uploadRef.current.clear();
        return;
      }

      // If the uploaded file has an allowed extension, set it as the uploaded file
      setUploadedFile(uploadedFile);

      processUploadedFile(uploadedFile).then((r) => {});
    }
  };

  /**
   * Process the uploaded file and set the image file
   * - If PDF, convert to image
   */
  const processUploadedFile = async (file) => {
    setIsImageFileLoading(true);

    try {
      // If PDF, convert to image
      if (file.name.slice(file.name.lastIndexOf(".")) === ".pdf") {
        // Convert PDF to image
        const formData = new FormData();

        formData.append("file", file);

        await api
          .post(`/ocr/api/pdf2image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "arraybuffer",
          })
          .then(async (response) => {
            const zip = await JSZip.loadAsync(response.data);
            const imageFiles = Object.values(zip.files);

            const imagesList = imageFiles.map(async (imageZip) => {
              const imageData = await imageZip.async("arraybuffer");

              // TODO get filetype from file, might have to fix backend
              const mimeType = imageZip._data.mimeType || "image/png";

              // Create a new File object from the image data
              const imageBlob = new Blob([imageData], {
                type: mimeType,
              });

              const imageFile = new File([imageBlob], imageZip.name, {
                type: mimeType,
              });

              // Need objectURL to display the image
              imageFile.objectURL = URL.createObjectURL(imageBlob);

              return imageFile;
            });

            const images = await Promise.all(imagesList);
            setPages(images);
            setIndexPage(0);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      // Else, we expect an image
      else {
        setPages([file]);
        setIndexPage(0);
      }
    } catch (error) {
    } finally {
      setIsImageFileLoading(false);
    }
  };

  // Go to the next step if the file is uploaded
  useEffect(() => {
    if (pages?.length > 0 && activeStep === 0) {
      setActiveStep(1);
    }
  }, [pages]);

  // Open OCR options when going to step 2 and there is no transcription
  useEffect(() => {
    if (activeStep === 2 && pages?.length && oCROptionsAuto) {
      setOCROptionsAuto(false);
      setDisplayOCROptions(true);
    }
  }, [activeStep, pages, oCROptionsAuto]);

  // Open translation options when going to step 3 and there is no translation
  useEffect(() => {
    if (activeStep === 3 && pages?.length && translationOptionsAuto) {
      setTranslationOptionsAuto(false);
      setDisplayTranslationOptions(true);
    }
  }, [activeStep, pages, translationOptionsAuto]);

  // Update viewVisibility when allow_text or allow_translation changes
  useEffect(() => {
    setViewVisibility({
      1: true,
      2: activeStep >= 2,
      3: activeStep >= 3,
    });
  }, [activeStep]);

  // Trigger to run analysis
  useEffect(() => {
    if (!runAnalysis) {
      return;
    }

    console.log("run analysis");

    setAnalysisFile(createFileFromTranscription(transcription));
    setRunAnalysis(false);
  }, [runAnalysis, transcription]);

  const OCRFile = async (file, pageIndex) => {
    let bodyFormData = new FormData();

    bodyFormData.append("file", file);

    await api
      .post(`/ocr/api/ocr`, bodyFormData, getFormConfig())
      .then((res) => {
        // Update the transcription and geojson arrays with the result for the corresponding page
        setTranscription((prevTranscriptions) =>
          updateArrayAtIndex(prevTranscriptions, pageIndex, res.data.text),
        );

        setGeojson((prevGeojson) =>
          updateArrayAtIndex(prevGeojson, pageIndex, res.data.geojson),
        );
      })
      .catch((e) => {
        console.error(`OCR failed for page ${pageIndex}`);

        toast.current.show({
          life: TOAST_LIFE,
          severity: "error",
          summary: "Text extraction",
          detail:
            "Something went wrong with OCR for one or more pages. Please try again or contact an administrator.",
        });

        setTranscription((prevTranscriptions) =>
          updateArrayAtIndex(prevTranscriptions, pageIndex, null),
        );
        setGeojson((prevGeojson) =>
          updateArrayAtIndex(prevGeojson, pageIndex, null),
        );
      });
  };

  const fileOCRer = async () => {
    toast.current.show({
      severity: "info",
      summary: "Scan uploaded",
      detail: "Extracting text...",
      life: TOAST_LIFE,
    });

    setIsOCRingFile(true);
    for (const page of pages) {
      const index = pages.indexOf(page);
      await OCRFile(page, index);
    }
    setIsOCRingFile(false);
  };

  const renderFileUpload = () => {
    return (
      <FileUpload
        chooseOptions={{
          className: "cl-primary-background cl-primary-border",
        }}
        ref={uploadRef}
        name="file_translation"
        maxFileSize={MAX_FILESIZE}
        accept={ALLOWED_FILE_EXTENSIONS.join(",")}
        customUpload
        auto={true}
        chooseLabel={"Choose scanned document"}
        emptyTemplate={
          <p className="m-0 text-sm">
            <i className="pi pi-info-circle"></i> Drag and drop or click to
            upload a document.
            <br />
            <small>
              Supported formats: {ALLOWED_FILE_EXTENSIONS.join(", ")}. Only one
              file can be processed at a time. The maximum file size is{" "}
              {(MAX_FILESIZE / 1000000).toLocaleString("en", {
                maximumSignificantDigits: 2,
              })}{" "}
              MB.
            </small>
          </p>
        }
        itemTemplate={itemTemplate}
        uploadHandler={(e) => fileUploader(e)}
        // TODO - be able to go back to step 0 and remove the file
        // disabled={uploadedFile !== null}
        contentClassName={"translate-upload-box"} // Custom min height to avoid jumping when file is uploaded
      />
    );
  };

  // Create a file from all the transcriptions concatenated
  const createFileFromTranscription = (transcription) => {
    const transcriptionText = transcription.join("\n");
    const transcriptionBlob = new Blob([transcriptionText], {
      type: "text/plain",
    });

    return new File([transcriptionBlob], "transcription.txt", {
      type: "text/plain",
    });
  };

  return (
    <div>
      <ProgressBar activeStep={activeStep} setActiveStep={setActiveStep} />

      {analysisFile && (
        <Analysis
          file={analysisFile}
          language={selectedSourceLanguage?.value || "en"}
          className={"mb-3"}
        />
      )}

      {activeStep > 0 && (
        <div className="flex flex-row justify-content-between mb-3">
          <div className="flex flex-row">
            <Button
              className={`${!pagesOpen ? "p-button-outlined" : ""}`}
              icon={`pi pi-th-large`}
              label="Pages"
              onClick={() => setPagesOpen(!pagesOpen)}
            />

            <div className={"flex flex-row ml-3 align-items-center"}>
              <Button
                className={"p-button-text"}
                icon={`pi pi-angle-left`}
                onClick={() => setIndexPage(indexPage - 1)}
                disabled={indexPage <= 0}
                text
              />
              <div className={"ml-1 mr-1"}>
                {`${indexPage + 1} / ${pages?.length}`}
              </div>
              <Button
                className={"p-button-text"}
                icon={`pi pi-angle-right`}
                onClick={() => setIndexPage(indexPage + 1)}
                disabled={indexPage >= pages.length - 1}
              />
            </div>
          </div>

          <div className="ocr-select-buttons align-items-end">
            {activeStep >= 2 && (
              <SelectButtonOCRView
                viewVisibility={viewVisibility}
                setViewVisibility={setViewVisibility}
                allow_text={activeStep >= 2}
                allow_translation={activeStep >= 3}
              />
            )}
          </div>
        </div>
      )}

      {activeStep === 0 && renderFileUpload()}

      {activeStep > 0 && (
        <div
          className="flex md:flex-row flex-column mt-3"
          style={{
            height: "75vh",
          }}
        >
          {pagesOpen && (
            <PageSelection
              pages={pages}
              selectedPage={indexPage}
              setSelectedPage={setIndexPage}
            />
          )}

          <OCRSplitview
            file_url={pages[indexPage]?.objectURL}
            text={transcription[indexPage]}
            setText={(text) => {
              setTranscription((prevTranscription) =>
                updateArrayAtIndex(prevTranscription, indexPage, text),
              );
            }}
            geojson={geojson[indexPage]}
            translation={translation[indexPage]}
            setTranslation={(text) => {
              setTranslation((prevTranslation) =>
                updateArrayAtIndex(prevTranslation, indexPage, text),
              );
            }}
            allow_text={activeStep >= 2}
            allow_translation={activeStep >= 3}
            viewVisibility={viewVisibility}
          />
        </div>
      )}

      <RenderActionMenu
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        indexPage={indexPage}
        isOCRingFile={isOCRingFile}
        isTranslating={isTranslating}
        transcription={transcription}
        setDisplayOCROptions={setDisplayOCROptions}
        setDisplayTranslationOptions={setDisplayTranslationOptions}
        setDisplayDownloadOptions={setDisplayDownloadOptions}
        toast={toast}
      />

      <Dialog
        header="Select OCR options"
        visible={displayOCROptions}
        onHide={() => setDisplayOCROptions(false)}
        style={{
          minWidth: "40vw",
          minHeight: "30vh",
        }}
        contentClassName={"flex flex-column justify-content-evenly"}
      >
        <div className="grid translate-ui-grid flex justify-content-center">
          <div className="flex flex-column mb-2">
            <label className="text-900 font-medium text-sm mb-2">
              OCR model
            </label>
            <Dropdown
              options={[{ value: "Default", label: "Default" }]}
              value={"Default"}
              placeholder="Select an option"
              onChange={(e) => {}}
              disabled
            />
          </div>
        </div>

        <div className="flex justify-content-center">
          <Button
            label="Extract text"
            onClick={() => {
              fileOCRer().then(() => {
                setRunAnalysis(true);
              });
              setDisplayOCROptions(false);
            }}
            disabled={isOCRingFile || indexPage === null}
          />
        </div>
      </Dialog>

      <Dialog
        header="Select translation options"
        visible={displayTranslationOptions}
        onHide={() => setDisplayTranslationOptions(false)}
        style={{
          minWidth: "80vw",
          minHeight: "50vh",
        }}
        contentClassName={"flex flex-column justify-content-between"}
      >
        <div className={"w-full"}>
          <TranslateEngineSelection
            enable_language_detection={false}
            languageDetectionMode={languageDetectionMode}
            setLanguageDetectionMode={setLanguageDetectionMode}
            selectedSourceLanguage={selectedSourceLanguage}
            setSelectedSourceLanguage={setSelectedSourceLanguage}
            selectedTargetLanguage={selectedTargetLanguage}
            setSelectedTargetLanguage={setSelectedTargetLanguage}
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
          />

          {/*<Analysis*/}
          {/*  file={analysisFile}*/}
          {/*  language={selectedSourceLanguage?.value || "en"}*/}
          {/*/>*/}
        </div>

        <div className="flex justify-content-center mt-2">
          <RenderTranslationFooter
            transcription={transcription}
            selectedSourceLanguage={selectedSourceLanguage}
            selectedTargetLanguage={selectedTargetLanguage}
            selectedProvider={selectedProvider}
            selectedDomain={selectedDomain}
            setTranslation={setTranslation}
            setIsTranslating={setIsTranslating}
            setDisplayTranslationOptions={setDisplayTranslationOptions}
            toast={toast}
          />
        </div>
      </Dialog>

      <Dialog
        header="Download results"
        visible={displayDownloadOptions}
        onHide={() => setDisplayDownloadOptions(false)}
      >
        <RenderDownloadMenu
          transcription={transcription}
          translation={translation}
          setDisplayDownloadOptions={setDisplayDownloadOptions}
          toast={toast}
        />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default Ocr;
